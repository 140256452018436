.subscribe {
  // position: absolute;
  width: 100%;
  // padding: 50px 30px;
  // padding: 46px 66px 70px 74px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  // border-radius: 10px;
  background: #fff;
}